import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ServicesAWS from '../../containers/Services/AWS';

const propTypes = {
  data: PropTypes.shape({
    aws: PropTypes.shape({
      title: PropTypes.string,
      icons: PropTypes.array.isRequired,
      awsSections: PropTypes.array.isRequired,
      awsPartnerNetworkLogo: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }),
      }).isRequired,
      networkDiagram: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }).isRequired,
      description: PropTypes.shape({
        json: PropTypes.shape({
          content: PropTypes.array,
          nodeType: PropTypes.string,
        }),
      }),
    }),
    companyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

const ServicesPage = ({ data }) => {
  return (
    <ServicesAWS
      title={data.aws.title}
      icons={data.aws.icons}
      awsPartnerNetworkLogo={data.aws.awsPartnerNetworkLogo}
      awsSections={data.aws.awsSections}
      companyQuote={data.companyQuote}
      description={data.aws.description}
      networkDiagram={data.aws.networkDiagram}
    />
  );
};

ServicesPage.propTypes = propTypes;
export default ServicesPage;

export const query = graphql`
  query {
    aws: contentfulAws {
      title
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      awsSections {
        slug
        title
        summary
      }
      awsPartnerNetworkLogo {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        json
      }
      networkDiagram {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    companyQuote: contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
