import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import CompanyQuote from '../../../components/CompanyQuote';
import Button from '../../../components/Button';
import Layout from '../../../components/Layout';
import renderContent from '../../../utils/rich-text-helpers';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import s from './AWS.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  icons: PropTypes.array.isRequired,
  awsSections: PropTypes.array.isRequired,
  awsPartnerNetworkLogo: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  networkDiagram: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  description: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array.isRequired,
    }),
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.array.isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/aws-services.png',
  ogTitle: "Elevate Your Cloud Strategy with Tech Holding's AWS Expertise",
  ogDesc:
    'Seamless AWS integration & migration solutions by Tech Holding, an AWS Advanced Consulting Partner. Optimize your cloud infrastructure for innovation.',
  ogPath: '/services/aws',
};

const updatedSeo = getSeoConfig(seoParams);

const ServicesAWS = ({
  title,
  icons,
  awsSections,
  companyQuote,
  awsPartnerNetworkLogo,
  description,
  networkDiagram,
}) => {
  return (
    <Layout
      currentPage="/services/aws"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title} key={title}>
              <h2 data-test="title">{title}</h2>
            </div>
            {awsPartnerNetworkLogo.fluid && (
              <div className={s.image} data-test="awsPartnerNetworkLogo">
                <Img className={s.emoji} fluid={awsPartnerNetworkLogo.fluid} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.awsContent}>
          <div className={s.content}>
            {description?.json?.content.map((content) => renderContent(content, s.paragraph))}
          </div>
          <div className={s.awsBadges}>
            {icons.length > 0 &&
              icons.map((icon) => (
                <div key={icon.fluid.src} data-test="image">
                  <Img fluid={icon.fluid} className={s.emoji} />
                </div>
              ))}
          </div>
        </div>
        <div data-test="networkDiagram">
          {networkDiagram?.fluid && (
            <Img fluid={networkDiagram?.fluid} data-test="rectangleImage" />
          )}
        </div>
        <div className={s.awsSectionsContainer}>
          {awsSections.map((awsSection) => (
            <div key={awsSection.title} data-test="awsSections">
              <div className={s.content}>
                <h2>{awsSection.title}</h2>
                <p>{awsSection.summary}</p>
              </div>
              <div className={s.button}>
                <Link to={`/services/aws/${awsSection.slug}`}>
                  <Button theme="grey">See Details</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <CompanyQuote companyQuote={companyQuote} />
    </Layout>
  );
};

ServicesAWS.propTypes = propTypes;
export default ServicesAWS;
